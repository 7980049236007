<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Admin Documents</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="documents" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="bd1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <!-- <template v-slot:left>
                                        <h5 class="p-m-0">Documents Of Vision Creative Group</h5>
                                    </template> -->
                                    <template v-slot:right>
                                        <Button label="Add Admin Document" icon="pi pi-plus" @click="onAddDocument" />

                                        <!-- <Toast />
                                        <FileUpload mode="basic" name="demo[]" url="./upload.php" accept="*" :maxFileSize="1000000" @upload="onUpload" chooseLabel="Add Client Document"/> -->
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column field="id" header="Sr." headerStyle="width: 5%">
                                    <template #body="{ index }">
                                        {{ index+=1 }}
                                    </template>
                                </Column>
                                <Column header="Document Name" class="p-text-capitalize" field="cd5" headerStyle="width: 75%"></Column>
                                <Column header="View" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <a v-if="data.cd7 != '' && data.cd7 != null"
                                            class="btn p-button-rounded indigo-color" target="_blank" :href="data.cd7"><i
                                                class="pi pi-eye"></i></a>
                                        <span v-if="data.cd7 == '' || data.cd7 == null">-</span>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditDocument(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit Document Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid" :header="dialogHeader">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <label for="document-type">
                            Document Type
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="document-type" v-model="documentType" :options="documentTypes" optionLabel="label"
                            required="true" :class="{ 'p-invalid': isSubmitted && !documentType }"
                            placeholder="Select Document" emptyMessage="No Records" appendTo="body" />
                        <small class="p-invalid p-error" v-if="v$.documentType.$error">{{
                            v$.documentType.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-4" v-if="this.documentType.value == 6">
                        <label for="documentName">
                            Document Name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="documentName" v-model.trim="documentName" required="true" maxlength="50" autofocus
                            :class="{ 'p-invalid': submitted && !documentName }" />
                        <small class="p-invalid p-error" v-if="v$.documentName.$error">{{
                            v$.documentName.$errors[0].$message
                        }}</small>

                    </div>

                    <div class="p-field p-col-12 p-md-12">
                        <label for="document">
                            Document
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <FileUpload mode="basic" v-model="document" name="demo[]" accept="application/pdf" :maxFileSize="1000000"
                            @select="onUpload" />

                        <small class="p-text-bold">{{ doc_file_name }}</small>
                        <small class="p-invalid p-error" v-if="v$.document.$error">{{
                            v$.document.$errors[0].$message
                        }}</small>
                        <small class="p-invalid p-error" v-if="!v$.document.$error && documentError">{{
                            documentError
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="from-date">
                            From Date
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="fromDate"
                            :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false">
                        </Calendar>
                        <small class="p-invalid p-error" v-if="v$.fromDate.$error">
                            {{ v$.fromDate.$errors[0].$message }}
                        </small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="from-date">
                            To Date
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="toDate"
                            :minDate="fromDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false">
                        </Calendar>
                        <small class="p-invalid p-error" v-if="v$.toDate.$error">
                            {{ v$.toDate.$errors[0].$message }}
                        </small>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <label for="remark">
                            Remark
                        </label>
                        <Textarea id="remark" v-model="remark" :autoResize="true" rows="3" cols="30" placeholder="Remark" maxlength="250" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addDocument" style="width: 90px;" :disabled="showLoader"></Button>
        </template>
    </Dialog>
    <!-- Add/Edit Document Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import moment from "moment";

export default {
    data() {
        return {
            documents: [],
            v$: useValidate(),
            isDialogOpen: false,
            isSubmitted: false,
            documentType: "",
            document: null,
            showLoader: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: 0,
            documentTypes: [],
            isEditDocument: false,
            fromDate: null,
            toDate: null,
            todaysDate: new Date(),
            remark: "",
            documentError: "",
            dialogHeader: "",
            validation: {},
            documentId: '',
            doc_file_name: "",
            documentName: '',
            localAdminName: '',
        };
    },
    validations() {
        this.validation = {
            documentType: { required: helpers.withMessage('Please select document type', required) },
            document: { required: helpers.withMessage('Please upload document', required) },
            fromDate: { required: helpers.withMessage('Please select from date', required) },
            toDate: { required: helpers.withMessage('Please select to date', required) },
            documentName: { requiredIf: helpers.withMessage('Please enter document name', requiredIf(this.documentType.value == 6)), },
        };
        return this.validation;
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.loading = true;
        this.documentTypes = [
            { value: 1, label: "Appointment letter of admin" },
            { value: 2, label: "Appointment letter of owner" },
            { value: 3, label: "Appointment letter of co-ordinator" },
            { value: 4, label: "Purchase Order" },
            { value: 5, label: "Technical Information" },
            { value: 6, label: "Other" },
        ];

        this.getAdminDocumentDetails({ clientid: this.routeParam, page_no: this.page });
    },
    methods: {
        onUpload(e) {
            /** On upload a file code goes here */
            this.doc_file_name = "";
            const file = e.files[0];
            let allowedExtensions = /(\.pdf|\.jpg|\.png)$/i;
            if (!file) {
                this.documentError = 'Please upload pdf';
            } else if (!file.size > 2048) {
                this.documentError = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.documentError = 'Invalid file type';
            } else {
                this.documentError = '';
                if (file && file.type.includes('/pdf') || file.type.includes('image/')) {
                    this.document = file;
                } else {
                    this.profileImageError = 'Required PDF file';
                    this.profileImage = '';
                }
            }
        },
        onAddDocument() {
            this.v$.$reset();
            this.doc_file_name = "";
            /** Open popup of add document gode goes here */
            this.dialogHeader = 'Add Document';
            this.isDialogOpen = true;
            this.documentType = '';
            this.fromDate = null;
            this.toDate = null;
            this.remark = null;
        },
        onEditDocument(data) {
            this.dialogHeader = "Edit Document";
            this.isDialogOpen = true;
            this.documentId = data.cd1;
            this.doc_file_name = data.cd6;
            if (data.cd4 == 6) {
                this.documentName = data.cd5;
                this.documentType = { value: data.cd4, label: "Other" } || '';
            }
            else
                this.documentType = { value: data.cd4, label: data.cd5 } || '';

            this.document = { url: data.cd7, name: data.cd6 };
            this.fromDate = new Date(data.cd8) || '';
            this.toDate = new Date(data.cd9) || '';
            this.remark = data.cd10 || '';
        },
        async getAdminDocumentDetails(params) {
            try {
                const result = await this.ApiService.getAdminDocumentDetails({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.documents = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });

            }
        },
        addDocument() {
            this.isSubmitted = true;
            this.v$.$validate();
            let formData = new FormData();
            if (this.documentId) {
                formData.append("cd1", this.documentId);
            }
            formData.append("cd2", this.routeParam);
            if (this.documentType.value == 6) { //if doc type is other
                formData.append("cd4", this.documentType.value);
                formData.append("cd5", this.documentName);
            } else {
                formData.append("cd4", this.documentType.value);
                formData.append("cd5", this.documentType.label);
            }
            formData.append("cd6", this.document.name);
            formData.append("cd7", this.document.url ? this.document.url : this.document);
            formData.append('cd8', moment(this.fromDate).format("YYYY-MM-DD"));
            formData.append('cd9', moment(this.toDate).format("YYYY-MM-DD"));
            formData.append("cd10", this.remark ? this.remark : '');
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateAdminDocuments(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.isDialogOpen = false;
                        this.getAdminDocumentDetails({ clientid: this.routeParam, page_no: this.page });
                        this.documentId = '';
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        deleteDocument() {
            /** Delete Document code goes here */
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAdminDocumentDetails({ clientid: this.routeParam, page_no: event.page });
        },
        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },
            });
        },
        goToPrevious() {
            router.push({
                name: "adminrole",
                params: { adminSubId: this.routeParam },
            });
        },
        goToNext() {
            router.push({
                name: "service-setting",
                params: { adminSubId: this.routeParam },
            });
        }
    }
};
</script>

<style scoped></style>
